.App {
  text-align: center;
}

footer {
  text-align: left;
  margin: 20px;
}

footer a {
  color: black;
}

.dark footer a {
  color: white;
}
