#home {
  padding-top: 200px;
  padding-bottom: 100px;
}

#about {
  padding-top: 100px;
  padding-bottom: 20px;
  margin: 0 20px;
}

#about p {
  margin-bottom: 16px;
}

#skills {
  padding-top: 100px;
  padding-bottom: 20px;
  margin: 0 20px;
}

#contact {
  padding-top: 100px;
  padding-bottom: 160px;
}
