@font-face {
  font-family: 'UbuntuMono';
  src: url("/public/UbuntuMono/UbuntuMono-Regular.ttf") format("truetype");
}

body {
  background-color: #f0f0f0;
  font-family: 'UbuntuMono', monospace;
}

h1 {
  font-size: 60px;
  font-weight: bold;
}

h2 {
  font-size: 36px;
  font-weight: bold;
}

h3 {
  font-weight: bold;
}

h4 {
  font-weight: bold;
  text-align: left;
}

p {
  font-size: 20px;
  text-align: left;
}

.dark {
  background-color: #222;
  color: #fff;
}

.dark h2 {
  color: yellow;
}
