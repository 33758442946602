.slick-prev:before,
.slick-next:before {
  color: black;
}

.slider {
  margin: 60px 0;
}

.slide {
  border-radius: 5px;
  background-image: linear-gradient(to bottom right, white, #ddd);
  padding: 30px;
  height: 380px;
}

.icons {
  height: 25%;
  display: inline-block;
  margin: 10px;
}

.icon {
  float: left;
}

.text {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark .slick-prev:before,
.dark .slick-next:before {
  color: white;
}

.dark .slide {
  background-image: linear-gradient(to bottom right, #333, #111);
}
