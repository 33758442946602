.navbar-nav a {
  font-size: 20px;
}

#nav-icons a {
  display: inline-block;
  margin: 0 10px;
}

#nav-toggle {
  border: 0;
  padding: 5px;
}

.dark .navbar-nav a {
  color: yellow;
}
