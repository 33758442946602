.form {
  border-radius: 5px;
  background-image: linear-gradient(to bottom right, white, #ddd);
  padding: 20px 10px;
  margin: 60px 0;
}

.form-group {
  margin: 16px;
}

::placeholder {
  font-size: 18px;
}

.left-align {
  text-align: left;
}

.dark .form {
  background-image: linear-gradient(to bottom right, #333, #111);
}

.dark .form-control {
  border: 0;
  background-color: #333;
  color: white;
}

.dark ::placeholder {
  color: white;
}
